/*
 * @Author: 脉迈
 * @Date: 2021-11-11 17:11:16
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 17:38:39
 */
import React, {useState,useEffect} from 'react'
import {createPortal} from 'react-dom';
import styles from './Recharge.module.scss';
import mycard from '../../static/image/mycard.png';
function Recharge(props:any) {
  const [node,setNode] = useState<any>('')
  useEffect(() => {
    let el:any = document.createElement('div')
    setNode(el)
    document.body.appendChild(el);
    return ()=>{
      document.body.removeChild(el);
    }
  }, [])
  return node && createPortal(
    <>
      <div className={styles.con}>
        <img src={mycard} className={styles.bi} alt=""/>
        <div className={styles.list}>
          <div className={styles.item}>GASH點卡（TW）</div>
          <div className={styles.item}>GASH會員轉點（TW）</div>
          <div className={styles.item}>GASH會員轉點（海外）</div>
          <div className={styles.item}>GASH手機市話</div>
          <div className={styles.item}>GASH信用卡</div>
        </div>
        <div className={styles.btn}  onClick={()=>{props.onClose()}}>確認充值</div>
      </div>
      <div className={styles.mark}  onClick={()=>{props.onClose()}}></div>
    </>,
    node
  )
}

export default Recharge;
