/*
 * @Author: 脉迈
 * @Date: 2021-11-11 15:23:01
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 18:18:55
 */
 // eslint-disable-next-line
export default `
<p>
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">為保障消費者（以下簡稱『甲方』：甲方個人資料於註冊相關賬號時填寫）使用本公司</span><span style="font-family:微软雅黑">&nbsp;（以下簡稱『乙方』）所提供之產品、網頁、遊戲及其他相關服務之相關權益，請於註冊登記使用前，詳細閱讀以下合約條款約定。</span></span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第一條　　法定代理人</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">為限制行為能力人者，本契約訂定時，應經</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之法定代理人同意，本契約始生效力；</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">為無行為能力人者，本契約之訂定，應由</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之法定代理人代為之。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">若有限制行為能力人未經同意或無行為能力人未由法定代理人代為付費購買點數致生法定代理人主張退費時，法定代理人得備妥證明文件並提出申請，經乙方確認後，退還未使用之遊戲費用。如有用詐術使人信其為有行為能力人或已得法定代理人之允許者，則不適用上述之退費說明。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">為促進兒童及少年身心健全發展，並保障其相關權益，甲方瞭解各別款遊戲均應依「遊戲軟體分級管理辦法」之分類標準標示分級（如果當地法律有遊戲分級之相關規定）。乙方已於本網站標示分級類別，甲方應注意並同意已符合相關法令對使用本級別服務之年齡要求。若</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">為限制行為能力人或無行為能力人，除應符合第一項規定外，並應於</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之法定代理人閱讀、瞭解並同意本契約之所有內容後，方得使用本遊戲服務，本契約條款變更時亦同。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第二條　　契約適用之範圍</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">提供</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">網路連線遊戲服務及其他相關服務（以下簡稱本遊戲服務），甲乙雙方關於本遊戲服務之權利義務，依本契約條款之約定定之。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第三條　　契約之內容</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">以下視為本契約之一部分，與本契約條款具有相同之效力：</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">一、</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">有關本遊戲服務之廣告或宣傳內容。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">二、計費制遊戲之費率表及遊戲管理規則。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本契約條款如有疑義時，應為</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">有利之解釋。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第四條　　名詞定義</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本契約之名詞定義如下：</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">一、網路連線遊戲：系指</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">透過電腦、智慧型裝置或其他電子化載具，連結網際網路至</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">指定之伺服器所進行連線遊戲。但不包括電子遊戲場業管理條例所稱電子遊戲機、單純區域連線或其他無需透過網路連結遊戲伺服器之遊戲服務。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">二、遊戲網站：系指由</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">為提供本遊戲服務所建置之網站。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">三、遊戲管理規則：系指由</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">訂立，供為規範遊戲進行方式之規則且不影響雙方契約上之權利義務者。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">四、遊戲歷程：系指自</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">登入本遊戲起至註銷本遊戲時止，電腦系統對</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">遊戲進行過程所為之紀錄。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">五、外掛程式：系指非由</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">提供，以影響或改變</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">網路連線遊戲運作為目的之程式。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第五條　　服務範圍</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本契約所提供之遊戲服務，系由</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">指定之伺服器，讓</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">透過網際網路連線登入使用本遊戲服務。但不包括</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">向網際網路接取服務業者申請接取網際網路之服務，及提供上網所需之各項硬體設備。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第六條　　遊戲登錄</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">申請使用本遊戲服務，應依申請流程，於遊戲網站登錄與身分證明文件相符之個人資料或其他必要資訊。為確保</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">使用本遊戲服務之權益，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">提供之資料應可驗證身分，若有不正確或已變更時，應立即通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">更新。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">若有為提供本遊戲服務與</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">聯絡之必要，而</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">未提供正確之個人資料或原提供之資料不符合真實且未更新，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得於</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">提供真實資料或更新資料前，暫停</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">遊戲進行及遊戲歷程查詢之服務。但</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">能證明其為契約當事人者，不在此限。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第七條　　契約解除權規定</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得於開始遊戲後七日內，以書面告知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">解除本契約，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">無需說明理由及負擔任何費用。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第八條　　計費方式</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本遊戲服務之收費計算方式為：</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">□免費制</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">□計時制（應敘明計價單位及幣別，計價單位最高不得逾二小時）</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">■其他費制</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本遊戲服務內（例如：遊戲商城、線上商店等）有提供需</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">額外付費購買之點數、商品或其他服務（例如：虛擬貨幣或寶物、進階道具等），</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應在官網首頁、遊戲登入頁面或購買頁面公告載明付款方式及商品資訊。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">費率調整時，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應於預定調整生效日三十日前於官網首頁、遊戲登入頁面或購買頁面公告；若</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">于註冊帳號時已登錄通訊資料者，並依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">登錄之通訊資料通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">費率如有調整時，應自調整生效日起按新費率計收；若新費率高於舊費率時，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">在新費率生效日前已於官網中登錄之付費購買點數或遊戲費用應依舊費率計收。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第九條　　本遊戲服務應載明之資訊</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應於官網首頁、遊戲登入頁面或購買頁面及遊戲套件包裝上載明以下事項：</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">一、依遊戲軟體分級管理辦法規定標示遊戲分級級別及禁止或適合使用之年齡層。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">二、進行本遊戲服務之最低軟硬體需求。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">三、有提供安全裝置者，其免費或付費資訊。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">四、有提供付費購買之機會中獎商品或活動，其活動內容、獎項、中獎及機率或稀有商品數量等資訊，並應記載「此為機會中獎商品，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">購買或參與活動不代表即可獲得特定商品」等提示。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第十條　　帳號與密碼之使用</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">完成註冊程式後取得之帳號及密碼，僅供</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">使用。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">前項之密碼得依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">提供之修改機制進行變更。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">人員（含客服人員、遊戲管理員）不得主動詢問</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之密碼。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應於契約終止後</span><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">三十</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">日內，保留</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之帳號及附隨於該帳號之電磁紀錄。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">契約非因可歸責</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之事由而終止者，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">於前項期間內辦理續用後，有權繼續使用帳號及附隨於該帳號之電磁紀錄。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第二項期間屆滿時，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">仍未辦理續用，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得刪除該帳號及附隨於該帳號之所有資料，但法令另有規定者不在此限。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第十一條　　帳號密碼遭非法使用之通知與處理</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">當事人一方如發現帳號、密碼被非法使用時，應立即通知對方並由</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">進行查證，經</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">確認有前述情事後，得暫停該組帳號或密碼之使用權，更換帳號或密碼予</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">，立即限制第三人就本遊戲服務之使用權利，並將相關處理方式揭載於遊戲管理規則。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應於暫時限制遊戲使用權利之時起，即刻以官網公告、簡訊、電子郵件、推播或其他雙方約定之方式通知前項第三人提出說明。如該第三人未於接獲通知時起七日內提出說明，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應直接回復遭不當移轉之電磁紀錄予</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">，如不能回復時可采其他雙方同意之相當補償方式，並於回復後解除對第三人之限制。但</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">有提供免費安全裝置（例如：防盜卡、電話鎖等）而</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">不使用或有其他可歸責於</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之事由，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">不負回復或補償責任。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第一項之第三人不同意</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">前項之處理時，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得依報案程式，循司法途徑處理。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">依第一項規定限制</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">或第三人之使用權時，在限制使用期間內，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">不得向</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">或第三人收取費用。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">如有申告不實之情形致生</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">或第三人權利受損時，應負一切法律責任。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第十二條　　遊戲歷程之保存與查詢</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應保存</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之個人遊戲歷程紀錄，且保存期間為</span><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">三十</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">日，以供</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">查詢。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得以書面、網路，或親至</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之服務中心申請調閱</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之個人遊戲歷程，且須提出與身分證明文件相符之個人資料以供查驗，查詢費用如下，由</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">負擔：</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">■免費。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">□____元（不得超過新臺幣二百元）。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">□其他計費方式（計費方式另行公告於官網首頁、遊戲登入頁面或購買頁面，其收費不得超過新臺幣二百元）。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">接獲</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之查詢申請，應提供第一項所列之</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">個人遊戲歷程，並於七日內以儲存媒介或書面、電子郵件方式提供資料。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第十三條　　個人資料</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">關於個人資料之保護，依相關法律規定處理。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">第十四條</span><span style="font-family:微软雅黑">&nbsp;　　電磁紀錄</span></span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本遊戲之所有電磁紀錄均屬</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">所有，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">並應維持</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">相關電磁紀錄之完整。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">對於前項電磁紀錄有使用支配之權利。但不包括本遊戲服務範圍外之移轉、收益行為。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">第十五條</span><span style="font-family:微软雅黑">&nbsp;　　資訊公開</span></span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應於遊戲網站上提供本遊戲服務之伺服器可容納人數、同時上線人數與連線狀況等相關資訊，並定期更新。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第十六條　　連線品質</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">為維護本遊戲服務相關系統及軟硬體設備而預先規劃暫停本遊戲服務之全部或一部時，應于七日前於官網首頁、遊戲登入頁面或購買頁面公告。但因臨時性、急迫性或不可歸責于</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之事由者，不在此限。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">因可歸責</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">事由，致</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">不能連線使用本遊戲服務時，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應立即更正或修復。對於</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">於無法使用期間遭扣除遊戲費用或遊戲內商品，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應返還遊戲費用或商品，無法返還時則應提供其他合理之補償。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第十七條　　企業經營者及</span></strong><strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span></strong><strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">責任</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應依本契約之規定負有於提供本服務時，維護其自身電腦系統，符合當時科技或專業水準可合理期待之安全性。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">電腦系統或電磁紀錄受到破壞，或電腦系統運作異常時，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應於採取合理之措施後盡速予以回復。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">違反前二項規定或因遊戲程式漏洞致生</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">損害時，應依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">受損害情形，負損害賠償責任。但</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">能證明其無過失者，得減輕其賠償責任。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">電腦系統發生第二項所稱情況時，於完成修復並正常運作之前，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">不得向</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">收取費用。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">因共用帳號、委託他人付費購買點數衍生與第三人間之糾紛，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得不予協助處理。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">第十八條</span><span style="font-family:微软雅黑">&nbsp;　遊戲管理規則</span></span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">為規範遊戲進行之方式，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應訂立合理公平之遊戲管理規則，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應遵守</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">公告之遊戲管理規則。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">遊戲管理規則之變更應依第二十一條之程式為之。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">遊戲管理規則有下列情形之一者，其規定無效：：</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">一、抵觸本契約之規定。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">二、剝奪或限制</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之契約上權利。但</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">依第十九條之規定處理者，不在此限。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第十九條　　違反遊戲管理規則之處理</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">除本契約另有規定外，有事實足證</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">於本遊戲服務中違反遊戲管理規則時，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應於官網首頁、遊戲登入頁面或購買頁面公告，並依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">登錄之通訊資料通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第一次違反遊戲管理規則，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">於一定期間內改善。經</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">通知改善而未改善者，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得依遊戲管理規則，按其情節輕重限制</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之遊戲使用權利。如</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">因同一事由再次違反遊戲管理規則時，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得立即依遊戲管理規則限制</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">進行遊戲之權利。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">依遊戲管理規則限制</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">進行遊戲之權利，每次不得超過</span><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">十四</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">日。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第二十條　　申訴權利</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">不滿意</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">提供之連線品質、遊戲管理、費用計費、其他相關之服務品質，或對</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">依遊戲管理規則之處置不服時，得於收到通知之翌日起七日內至</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之服務中心或以電子郵件或書面提出申訴，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應於接獲申訴後，於</span><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">十五</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">日內回復處理之結果。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應於官網或遊戲管理規則中明定服務專線、電子郵件等相關聯絡資訊與二十四小時申訴管道。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">反映第三人利用外掛程式或其他影響遊戲公平性之申訴，依第一項規定辦理。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第二十一條　　契約之變更</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">修改本契約時，應於官網首頁、遊戲登入頁面或購買頁面公告之，並依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">登錄之通訊資料通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">未依前項進行公告及通知者，其契約之變更無效。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">于第一項通知到達後十五日內：。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">一、</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">未為反對之表示者，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">依契約變更後之內容繼續提供本遊戲服務。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">二、</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">為反對之表示者，依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">終止契約方式處理。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第二十二條　　契約之終止及退費</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得隨時通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">終止本契約。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得與</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">約定，若</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">逾一年期間未登入使用本遊戲服務，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">提前十五日通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">登入，如</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">屆期仍未登入使用，則</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得終止本契約。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">有下列重大情事之一者，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">登錄之通訊資料通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">後，得立即終止本契約：</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">（一）利用任何系統或工具對</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">電腦系統之惡意攻擊或破壞。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">（二）以利用外掛程式、病毒程式、遊戲程式漏洞或其他違反遊戲常態設定或公平合理之方式進行遊戲。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">（三）以冒名、詐騙或其他虛偽不正等方式付費購買點數或遊戲內商品。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">（四）因同一事由違反遊戲管理規則達三次及以上，經依第十五點第二項通知改善而未改善者。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">（五）經司法機關查獲從事任何不法之行為。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">契約終止時，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">于扣除必要成本後，應於三十日內以現金、信用卡、匯票或掛號寄發支票方式退還</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">未使用之付費購買之點數或遊戲費用，或依雙方同意之方式處理前述點數或費用。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第二十三條　　停止營運</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">因</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">停止本遊戲服務之營運而終止契約者，應於停止終止前</span><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">三十</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">日公告於官網首頁、遊戲登錄頁面或購買頁面，並依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">登錄之通訊資料通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">未依前項期間公告並通知，除應退還</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">未使用之付費購買點數或遊戲費用且不得扣除必要成本外，並應提供其他合理之補償。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第二十四條　　送達</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">有關本契約所有事項之通知，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">同意</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">登錄之通訊資料為送達。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">前項登錄通訊資料若有變更，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應即通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應依變更後之通訊資料為送達。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">登錄之通訊資料所為之通知發出後，以書面通知到達</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">，或電子郵件進入</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之電子郵件伺服器中，推定為已送達。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">因</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之故意或過失致</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">無法為送達者，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">對</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">因無法送達所致之損害不負賠償責任。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第二十五條　　准據法</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本契約以中華民國法律為准據法</span>
</p>
<p style="margin-top:14px;margin-right:0;margin-bottom:0;margin-left:0;text-indent:0;line-height:24px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第二十六條　　管轄法院</span></strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><br/></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">與</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">得合意定第一審管轄法院。</span>
</p>
<p style="margin-top:14px;margin-right:0;margin-bottom:0;margin-left:0;text-indent:0;line-height:24px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">如您有任何疑問，請聯繫</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">客服部門。</span>
</p>
<p>
    <br/>
</p>
`