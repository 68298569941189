/*
 * @Author: 脉迈
 * @Date: 2021-11-11 15:23:01
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 18:21:06
 */
 // eslint-disable-next-line
export default `
<h3 style="margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">一、陳述與保證</span></strong>
</h3>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">絕對尊</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">重使用者的隱私和資訊，並會對此嚴密保護。我們對用戶做出如下解釋和說明</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">1，資訊手機目的</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">收集使用者資料僅限用於協助您的購買消費、服務記錄、累計金額、資訊傳送等。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">2，Cookies</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">為了更好地為使用者服務，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">可能會使用</span><span style="font-family:微软雅黑">cookies來儲存並在某些時刻追蹤使用者的相關資料。上述使用僅限於：估算流量人數、統計用戶偏好等商業、市場分析或學術用途。Cookies可供協力廠商使用，例如廣告服務等需要可辨識資料、資料等作為評估指標和資料，這些資料僅供分析使用。</span></span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">3，關聯網站</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">我們所提供的其它網站連結，可能各有其不同的聲明或使用規則等，請務必自行謹慎瞭解，本網站並不保護使用者於該等網站中的隱私權。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">4，本聲明的變更</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本聲明將保留得以隨時增刪修飾本隱私權聲明之權利，任何增刪修飾後之聲明，應以本聲明時間為即時生效日。</span>
</p>
<h3 style="margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">二、用戶帳號使用與保管</span></strong>
</h3>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">1，根據必備條款的約定，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">有權審查用戶註冊所提供的身份資訊是否真實、有效，並應積極地採取技術與管理等合理措施保障用戶帳號的安全、有效；使用者有義務妥善保管其帳號及密碼，並正確、安全地使用其帳號及密碼。任何一方未盡上述義務導致帳號密碼遺失、帳號被盜等情形而給用戶和他人的民事權利造成損害的，應當承擔法律責任。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">2，用戶對登錄後所持帳號產生的行為依法享有權利和承擔責任。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">3，使用者發現其帳號或密碼被他人非法使用或有使用異常的情況的，應及時根據</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">公佈的處理方式通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">我们</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">，並有權通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">採取措施暫停該帳號的登錄和使用。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">4，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">根據使用者的通知採取措施暫停用戶帳號的登錄和使用的，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應當要求用戶提供並核實與其註冊身份資訊相一致的個人有效身份資訊。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">4.1，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">核實用戶所提供的個人有效身份資訊與所註冊的身份資訊相一致的，應當及時採取措施暫停用戶帳號的登錄和使用。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">4.2,</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">&nbsp;<span style="font-family:微软雅黑">本公司</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">違反</span><span style="font-family:微软雅黑">4.1款項的約定，未及時採取措施暫停用戶帳號的登錄和使用，因此而給用戶造成損失的，應當承擔其相應的法律責任。</span></span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">4.3 ，用戶沒有提供其個人有效身份證件或者用戶提供的個人有效身份證件與所註冊的身份資訊不一致的，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">有權拒絕用戶上述請求。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">5， 用戶為了維護其合法權益，向</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">提供與所註冊的身份資訊相一致的個人有效身份資訊時，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應當為用戶提供帳號註冊人證明、原始註冊資訊等必要的協助和支援，並根據需要向有關行政機關和司法機關提供相關證據資訊資料。</span>
</p>
<h3 style="margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">三、服務的中止與終止</span></strong>
</h3>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">1，使用者有發佈違法資訊、嚴重違背社會公德、以及其他違反法律禁止性規定的行為，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應當立即終止對使用者提供服務。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">2，使用者在接受</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">服務時實施不正當行為的，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">有權終止對使用者提供服務。該不正當行為的具體情形應當在本協議中有明確約定或屬於</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">事先明確告知的應被終止服務的禁止性行為，否則，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">不得終止對使用者提供服務。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">3，使用者提供虛假註冊身份資訊，或實施違反本協議的行為，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">有權中止對使用者提供全部或部分服務；</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">採取中止措施應當通知用戶並告知中止期間，中止期間應該是合理的，中止期間屆滿</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應當及時恢復對使用者的服務。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">4，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">根據本條約定中止或終止對使用者提供部分或全部服務的，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">應負舉證責任。</span>
</p>
<h3 style="margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">四、使用者資訊保護</span></strong>
</h3>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">1， </span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">要求用戶提供與其個人身份有關的資訊資料時，應當事先以明確而易見的方式向使用者公開其隱私權保護政策和個人資訊利用政策，並採取必要措施保護使用者資訊。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">2，未經用戶許可</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">不得向任何協力廠商提供、公開或共用使用者註冊資料中的姓名、個人有效身份證件號碼、聯繫方式、家庭住址等個人身份資訊，但下列情況除外：用戶或用戶監護人授權</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">披露的；</span> <span style="font-family:微软雅黑">有關法律要求</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">披露的；</span> <span style="font-family:微软雅黑">司法機關或行政機關基於法定程式要求</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">提供的；</span> </span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">為了維護自己合法權益而向用戶提起訴訟或者仲裁時；</span> <span style="font-family:微软雅黑">應用戶監護人的合法要求而提供使用者個人身份資訊時。</span></span>
</p>
<p>
    <br/>
</p>
`