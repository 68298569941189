/*
 * @Author: 脉迈
 * @Date: 2021-11-11 17:11:27
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 18:13:42
 */
/*eslint-disable */
import React, {useState,useEffect} from 'react'
import {createPortal} from 'react-dom';
import styles from './Login.module.scss';
function Login(props:any) {
  const [node,setNode] = useState<any>('')
  useEffect(() => {
    let el:any = document.createElement('div')
    setNode(el)
    document.body.appendChild(el);
    return ()=>{
      document.body.removeChild(el);
    }
  }, [])
  return node && createPortal(
    <>
    <div>
      <div className={styles.login}>
        <div className={styles.title}>會員登入</div>
        <div className={styles.container}>
          <div className={styles.item}>
            <i className={styles.account_icon}></i>
            <input type="text" className={styles.input} placeholder="請輸入會員賬號"/>
          </div>
          <div className={styles.item}>
            <i className={styles.pass_icon}></i>
            <input type="text" className={styles.input} placeholder="請輸入密碼"/>
          </div>
          <div className={styles.submit} onClick={()=>{props.onClose()}}>登入</div>
        </div>
        <div className={styles.other}>
          <a href="#" className={`${styles.item} ${styles.b1}`}></a>
          <a href="#" className={`${styles.item} ${styles.b2}`}></a>
          <a href="#" className={`${styles.item} ${styles.b3}`}></a>
        </div>
        <div className={styles.radius}></div>
      </div>
    </div>
    <div className={styles.mark} onClick={()=>{props.onClose()}}></div>
    </>,
    node
  )
}

export default Login;