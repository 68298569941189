/*
 * @Author: 脉迈
 * @Date: 2021-11-11 16:03:28
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 18:24:23
 */
import React from 'react';
import {Switch,Route} from 'react-router-dom';
import Index from './pages/index/index';
import Yszc from './pages/yszc/index';
import Yhxy from './pages/yhxy/index';

function App() {
  return (
    <div>
      <Switch>
        <Route path="/" component={Index} exact></Route>
        <Route path="/yszc" component={Yszc} exact></Route>
        <Route path="/yhxy" component={Yhxy} exact></Route>
      </Switch>
    </div>
  );
}

export default App;
