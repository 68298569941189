/*
 * @Author: 脉迈
 * @Date: 2021-11-11 15:22:38
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 18:21:44
 */
 // eslint-disable-next-line
export default `
<h3 style="margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">一、陈述与保证</span></strong>
</h3>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">绝对尊</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">重使用者的隐私和信息，并会对此严密保护。我们对用户做出如下解释和说明</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">1，信息手机目的</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">收集使用者资料仅限用于协助您的购买消费、服务记录、累计金额、信息传送等。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">2，Cookies</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">为了更好地为使用者服务，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">可能会使用</span><span style="font-family:微软雅黑">cookies来储存并在某些时刻追踪使用者的相关资料。上述使用仅限于：估算流量人数、统计用户偏好等商业、市场分析或学术用途。Cookies可供协力厂商使用，例如广告服务等需要可辨识资料、资料等作为评估指标和资料，这些资料仅供分析使用。</span></span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">3，关联网站</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">我们所提供的其它网站连结，可能各有其不同的声明或使用规则等，请务必自行谨慎了解，本网站并不保护使用者于该等网站中的隐私权。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">4，本声明的变更</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本声明将保留得以随时增删修饰本隐私权声明之权利，任何增删修饰后之声明，应以本声明时间为实时生效日。</span>
</p>
<h3 style="margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">二、用户账号使用与保管</span></strong>
</h3>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">1，根据必备条款的约定，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">有权审查用户注册所提供的身份信息是否真实、有效，并应积极地采取技术与管理等合理措施保障用户账号的安全、有效；使用者有义务妥善保管其账号及密码，并正确、安全地使用其账号及密码。任何一方未尽上述义务导致账号密码遗失、账号被盗等情形而给用户和他人的民事权利造成损害的，应当承担法律责任。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">2，用户对登录后所持账号产生的行为依法享有权利和承担责任。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">3，使用者发现其账号或密码被他人非法使用或有使用异常的情况的，应及时根据</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">公布的处理方式通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">我们</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">，并有权通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">采取措施暂停该账号的登录和使用。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">4，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">根据使用者的通知采取措施暂停用户账号的登录和使用的，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应当要求用户提供并核实与其注册身份信息相一致的个人有效身份信息。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">4.1，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">核实用户所提供的个人有效身份信息与所注册的身份信息相一致的，应当及时采取措施暂停用户账号的登录和使用。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">4.2,</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">&nbsp;<span style="font-family:微软雅黑">本公司</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">违反</span><span style="font-family:微软雅黑">4.1款项的约定，未及时采取措施暂停用户账号的登录和使用，因此而给用户造成损失的，应当承担其相应的法律责任。</span></span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">4.3 ，用户没有提供其个人有效身份证件或者用户提供的个人有效身份证件与所注册的身份信息不一致的，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">有权拒绝用户上述请求。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">5， 用户为了维护其合法权益，向</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">提供与所注册的身份信息相一致的个人有效身份信息时，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应当为用户提供账号注册人证明、原始注册信息等必要的协助和支援，并根据需要向有关行政机关和司法机关提供相关证据信息资料。</span>
</p>
<h3 style="margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">三、服务的中止与终止</span></strong>
</h3>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">1，使用者有发布违法信息、严重违背社会公德、以及其他违反法律禁止性规定的行为，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应当立即终止对使用者提供服务。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">2，使用者在接受</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">服务时实施不正当行为的，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">有权终止对使用者提供服务。该不正当行为的具体情形应当在本协议中有明确约定或属于</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">事先明确告知的应被终止服务的禁止性行为，否则，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">不得终止对使用者提供服务。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">3，使用者提供虚假注册身份信息，或实施违反本协议的行为，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">有权中止对使用者提供全部或部分服务；</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">采取中止措施应当通知用户并告知中止期间，中止期间应该是合理的，中止期间届满</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应当及时恢复对使用者的服务。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">4，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">根据本条约定中止或终止对使用者提供部分或全部服务的，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应负举证责任。</span>
</p>
<h3 style="margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">四、使用者信息保护</span></strong>
</h3>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">1， </span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">要求用户提供与其个人身份有关的信息资料时，应当事先以明确而易见的方式向使用者公开其隐私权保护政策和个人信息利用政策，并采取必要措施保护使用者信息。</span>
</p>
<p style="margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">2，未经用户许可</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">不得向任何协力厂商提供、公开或共享使用者注册资料中的姓名、个人有效身份证件号码、联系方式、家庭住址等个人身份信息，但下列情况除外：用户或用户监护人授权</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">披露的；</span> <span style="font-family:微软雅黑">有关法律要求</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">披露的；</span> <span style="font-family:微软雅黑">司法机关或行政机关基于法定程序要求</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">提供的；</span> </span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本公司</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">为了维护自己合法权益而向用户提起诉讼或者仲裁时；</span> <span style="font-family:微软雅黑">应用户监护人的合法要求而提供使用者个人身份信息时。</span></span>
</p>
<p>
    <br/>
</p>
`