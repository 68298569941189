/*
 * @Author: 脉迈
 * @Date: 2021-11-11 15:22:38
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 18:20:07
 */
 // eslint-disable-next-line
export default `
<p>
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">为保障消费者（以下简称『甲方』：甲方个人资料于注册相关账号时填写）使用本公司</span><span style="font-family:微软雅黑">&nbsp;（以下简称『乙方』）所提供之产品、网页、游戏及其他相关服务之相关权益，请于注册登记使用前，详细阅读以下合约条款约定。</span></span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第一条　　法定代理人</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">为限制行为能力人者，本契约订定时，应经</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之法定代理人同意，本契约始生效力；</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">为无行为能力人者，本契约之订定，应由</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之法定代理人代为之。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">若有限制行为能力人未经同意或无行为能力人未由法定代理人代为付费购买点数致生法定代理人主张退费时，法定代理人得备妥证明文件并提出申请，经乙方确认后，退还未使用之游戏费用。如有用诈术使人信其为有行为能力人或已得法定代理人之允许者，则不适用上述之退费说明。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">为促进儿童及少年身心健全发展，并保障其相关权益，甲方了解各别款游戏均应依「游戏软件分级管理办法」之分类标准标示分级（如果当地法律有游戏分级之相关规定）。乙方已于本网站标示分级类别，甲方应注意并同意已符合相关法令对使用本级别服务之年龄要求。若</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">为限制行为能力人或无行为能力人，除应符合第一项规定外，并应于</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之法定代理人阅读、了解并同意本契约之所有内容后，方得使用本游戏服务，本契约条款变更时亦同。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第二条　　契约适用之范围</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">提供</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">网络连线游戏服务及其他相关服务（以下简称本游戏服务），甲乙双方关于本游戏服务之权利义务，依本契约条款之约定定之。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第三条　　契约之内容</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">以下视为本契约之一部分，与本契约条款具有相同之效力：</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">一、</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">有关本游戏服务之广告或宣传内容。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">二、计费制游戏之费率表及游戏管理规则。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本契约条款如有疑义时，应为</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">有利之解释。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第四条　　名词定义</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本契约之名词定义如下：</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">一、网络连线游戏：系指</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">透过计算机、智能型装置或其他电子化载具，连结网际网络至</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">指定之服务器所进行连线游戏。但不包括电子游戏场业管理条例所称电子游戏机、单纯区域连线或其他无需透过网络连结游戏服务器之游戏服务。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">二、游戏网站：系指由</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">为提供本游戏服务所建置之网站。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">三、游戏管理规则：系指由</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">订立，供为规范游戏进行方式之规则且不影响双方契约上之权利义务者。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">四、游戏历程：系指自</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">登入本游戏起至注销本游戏时止，计算机系统对</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">游戏进行过程所为之纪录。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">五、外挂程序：系指非由</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">提供，以影响或改变</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">网络连线游戏运作为目的之程序。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第五条　　服务范围</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本契约所提供之游戏服务，系由</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">指定之服务器，让</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">透过网际网络连线登入使用本游戏服务。但不包括</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">向网际网络接取服务业者申请接取网际网络之服务，及提供上网所需之各项硬设备。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第六条　　游戏登录</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">申请使用本游戏服务，应依申请流程，于游戏网站登录与身分证明文件相符之个人资料或其他必要信息。为确保</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">使用本游戏服务之权益，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">提供之资料应可验证身分，若有不正确或已变更时，应立即通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">更新。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">若有为提供本游戏服务与</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">联络之必要，而</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">未提供正确之个人资料或原提供之资料不符合真实且未更新，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得于</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">提供真实资料或更新资料前，暂停</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">游戏进行及游戏历程查询之服务。但</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">能证明其为契约当事人者，不在此限。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第七条　　契约解除权规定</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得于开始游戏后七日内，以书面告知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">解除本契约，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">无需说明理由及负担任何费用。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第八条　　计费方式</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本游戏服务之收费计算方式为：</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">□免费制</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">□计时制（应叙明计价单位及币别，计价单位最高不得逾二小时）</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">■其他费制</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本游戏服务内（例如：游戏商城、在线商店等）有提供需</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">额外付费购买之点数、商品或其他服务（例如：虚拟货币或宝物、进阶道具等），</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应在官网首页、游戏登入页面或购买页面公告载明付款方式及商品信息。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">费率调整时，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应于预定调整生效日三十日前于官网首页、游戏登入页面或购买页面公告；若</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">于注册账号时已登录通讯资料者，并依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">登录之通讯资料通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">费率如有调整时，应自调整生效日起按新费率计收；若新费率高于旧费率时，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">在新费率生效日前已于官网中登录之付费购买点数或游戏费用应依旧费率计收。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第九条　　本游戏服务应载明之信息</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应于官网首页、游戏登入页面或购买页面及游戏套件包装上载明以下事项：</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">一、依游戏软件分级管理办法规定标示游戏分级级别及禁止或适合使用之年龄层。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">二、进行本游戏服务之最低软硬件需求。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">三、有提供安全装置者，其免费或付费信息。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">四、有提供付费购买之机会中奖商品或活动，其活动内容、奖项、中奖及机率或稀有商品数量等信息，并应记载「此为机会中奖商品，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">购买或参与活动不代表即可获得特定商品」等提示。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第十条　　账号与密码之使用</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">完成注册程序后取得之账号及密码，仅供</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">使用。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">前项之密码得依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">提供之修改机制进行变更。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">人员（含客服人员、游戏管理员）不得主动询问</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之密码。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应于契约终止后</span><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">三十</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">日内，保留</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之账号及附随于该账号之电磁纪录。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">契约非因可归责</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之事由而终止者，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">于前项期间内办理续用后，有权继续使用账号及附随于该账号之电磁纪录。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第二项期间届满时，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">仍未办理续用，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得删除该账号及附随于该账号之所有资料，但法令另有规定者不在此限。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第十一条　　账号密码遭非法使用之通知与处理</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">当事人一方如发现账号、密码被非法使用时，应立即通知对方并由</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">进行查证，经</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">确认有前述情事后，得暂停该组账号或密码之使用权，更换账号或密码予</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">，立即限制第三人就本游戏服务之使用权利，并将相关处理方式揭载于游戏管理规则。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应于暂时限制游戏使用权利之时起，即刻以官网公告、简讯、电子邮件、推播或其他双方约定之方式通知前项第三人提出说明。如该第三人未于接获通知时起七日内提出说明，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应直接回复遭不当移转之电磁纪录予</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">，如不能回复时可采其他双方同意之相当补偿方式，并于回复后解除对第三人之限制。但</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">有提供免费安全装置（例如：防盗卡、电话锁等）而</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">不使用或有其他可归责于</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之事由，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">不负回复或补偿责任。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第一项之第三人不同意</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">前项之处理时，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得依报案程序，循司法途径处理。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">依第一项规定限制</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">或第三人之使用权时，在限制使用期间内，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">不得向</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">或第三人收取费用。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">如有申告不实之情形致生</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">或第三人权利受损时，应负一切法律责任。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第十二条　　游戏历程之保存与查询</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应保存</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之个人游戏历程纪录，且保存期间为</span><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">三十</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">日，以供</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">查询。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得以书面、网络，或亲至</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之服务中心申请调阅</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之个人游戏历程，且须提出与身分证明文件相符之个人资料以供查验，查询费用如下，由</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">负担：</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">■免费。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">□____元（不得超过新台币二百元）。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">□其他计费方式（计费方式另行公告于官网首页、游戏登入页面或购买页面，其收费不得超过新台币二百元）。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">接获</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之查询申请，应提供第一项所列之</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">个人游戏历程，并于七日内以储存媒介或书面、电子邮件方式提供资料。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第十三条　　个人资料</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">关于个人资料之保护，依相关法律规定处理。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">第十四条</span><span style="font-family:微软雅黑">&nbsp;　　电磁纪录</span></span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本游戏之所有电磁纪录均属</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">所有，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">并应维持</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">相关电磁纪录之完整。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">对于前项电磁纪录有使用支配之权利。但不包括本游戏服务范围外之移转、收益行为。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">第十五条</span><span style="font-family:微软雅黑">&nbsp;　　信息公开</span></span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应于游戏网站上提供本游戏服务之服务器可容纳人数、同时上线人数与连线状况等相关信息，并定期更新。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第十六条　　连线质量</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">为维护本游戏服务相关系统及软硬件设备而预先规划暂停本游戏服务之全部或一部时，应于七日前于官网首页、游戏登入页面或购买页面公告。但因临时性、急迫性或不可归责于</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之事由者，不在此限。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">因可归责</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">事由，致</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">不能连线使用本游戏服务时，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应立即更正或修复。对于</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">于无法使用期间遭扣除游戏费用或游戏内商品，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应返还游戏费用或商品，无法返还时则应提供其他合理之补偿。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第十七条　　企业经营者及</span></strong><strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span></strong><strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">责任</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应依本契约之规定负有于提供本服务时，维护其自身计算机系统，符合当时科技或专业水平可合理期待之安全性。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">计算机系统或电磁纪录受到破坏，或计算机系统运作异常时，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应于采取合理之措施后尽速予以回复。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">违反前二项规定或因游戏程序漏洞致生</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">损害时，应依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">受损害情形，负损害赔偿责任。但</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">能证明其无过失者，得减轻其赔偿责任。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">计算机系统发生第二项所称情况时，于完成修复并正常运作之前，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">不得向</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">收取费用。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">因共享账号、委托他人付费购买点数衍生与第三人间之纠纷，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得不予协助处理。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><span style="font-family:微软雅黑">第十八条</span><span style="font-family:微软雅黑">&nbsp;　游戏管理规则</span></span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">为规范游戏进行之方式，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应订立合理公平之游戏管理规则，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应遵守</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">公告之游戏管理规则。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">游戏管理规则之变更应依第二十一条之程序为之。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">游戏管理规则有下列情形之一者，其规定无效：：</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">一、抵触本契约之规定。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">二、剥夺或限制</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之契约上权利。但</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">依第十九条之规定处理者，不在此限。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第十九条　　违反游戏管理规则之处理</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">除本契约另有规定外，有事实足证</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">于本游戏服务中违反游戏管理规则时，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应于官网首页、游戏登入页面或购买页面公告，并依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">登录之通讯资料通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第一次违反游戏管理规则，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">于一定期间内改善。经</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">通知改善而未改善者，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得依游戏管理规则，按其情节轻重限制</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之游戏使用权利。如</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">因同一事由再次违反游戏管理规则时，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得立即依游戏管理规则限制</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">进行游戏之权利。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">依游戏管理规则限制</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">进行游戏之权利，每次不得超过</span><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">十四</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">日。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第二十条　　申诉权利</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">不满意</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">提供之连线质量、游戏管理、费用计费、其他相关之服务质量，或对</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">依游戏管理规则之处置不服时，得于收到通知之翌日起七日内至</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之服务中心或以电子邮件或书面提出申诉，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应于接获申诉后，于</span><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">十五</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">日内回复处理之结果。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应于官网或游戏管理规则中明定服务专线、电子邮件等相关联络信息与二十四小时申诉管道。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">反映第三人利用外挂程序或其他影响游戏公平性之申诉，依第一项规定办理。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第二十一条　　契约之变更</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">修改本契约时，应于官网首页、游戏登入页面或购买页面公告之，并依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">登录之通讯资料通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">未依前项进行公告及通知者，其契约之变更无效。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">于第一项通知到达后十五日内：。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">一、</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">未为反对之表示者，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">依契约变更后之内容继续提供本游戏服务。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">二、</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">为反对之表示者，依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">终止契约方式处理。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第二十二条　　契约之终止及退费</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得随时通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">终止本契约。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得与</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">约定，若</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">逾一年期间未登入使用本游戏服务，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">提前十五日通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">登入，如</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">届期仍未登入使用，则</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">得终止本契约。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">有下列重大情事之一者，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">登录之通讯资料通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">后，得立即终止本契约：</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">（一）利用任何系统或工具对</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">计算机系统之恶意攻击或破坏。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">（二）以利用外挂程序、病毒程序、游戏程序漏洞或其他违反游戏常态设定或公平合理之方式进行游戏。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">（三）以冒名、诈骗或其他虚伪不正等方式付费购买点数或游戏内商品。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">（四）因同一事由违反游戏管理规则达三次及以上，经依第十五点第二项通知改善而未改善者。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">（五）经司法机关查获从事任何不法之行为。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">契约终止时，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">于扣除必要成本后，应于三十日内以现金、信用卡、汇票或挂号寄发支票方式退还</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">未使用之付费购买之点数或游戏费用，或依双方同意之方式处理前述点数或费用。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第二十三条　　停止营运</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">因</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">停止本游戏服务之营运而终止契约者，应于停止终止前</span><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">三十</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">日公告于官网首页、游戏登录页面或购买页面，并依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">登录之通讯资料通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">未依前项期间公告并通知，除应退还</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">未使用之付费购买点数或游戏费用且不得扣除必要成本外，并应提供其他合理之补偿。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第二十四条　　送达</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">有关本契约所有事项之通知，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">同意</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">登录之通讯资料为送达。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">前项登录通讯资料若有变更，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应即通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">应依变更后之通讯资料为送达。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">依</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">登录之通讯资料所为之通知发出后，以书面通知到达</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">，或电子邮件进入</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之电子邮件服务器中，推定为已送达。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">因</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">之故意或过失致</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">无法为送达者，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">对</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">因无法送达所致之损害不负赔偿责任。</span>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第二十五条　　准据法</span></strong>
</p>
<p style="margin-top:7px;margin-right:0;margin-bottom:7px;margin-left:0;text-indent:0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">本契约以中华民国法律为准据法</span>
</p>
<p style="margin-top:14px;margin-right:0;margin-bottom:0;margin-left:0;text-indent:0;line-height:24px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px">第二十六条　　管辖法院</span></strong><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 18px"><br/></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">甲方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">与</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">得合意定第一审管辖法院。</span>
</p>
<p style="margin-top:14px;margin-right:0;margin-bottom:0;margin-left:0;text-indent:0;line-height:24px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">如您有任何疑问，请联系</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">乙方</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 16px">客服部门。</span>
</p>
<p>
    <br/>
</p>
`