/*
 * @Author: 脉迈
 * @Date: 2021-11-11 16:15:15
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 18:27:12
 */
import React, {useState} from 'react';
import styles from './index.module.scss';
import g1 from '../../static/image/1.png'
import g2 from '../../static/image/2.png'
import g3 from '../../static/image/3.png'
import user from '../../static/image/user.png'
import Reacharge from '../../components/Recharge/Recharge';
import Login from '../../components/Login/Login';
import Footer from '../../components/Footer/Footer';
function Index() {
  const [showRecharge, setShowRecharge] = useState(false)
  const [showLogin, setShowLogin] = useState(false)
  return (
    <>
    <div style={{minHeight:"100vh"}}>
      <div className={styles.login} onClick={()=>{setShowLogin(true)}}>
        <img src={user} alt=""/>
        <span>[登入]</span>
      </div>
      <div className={styles.con}>
        <div className={styles.select}>選擇伺服器</div>
        <div className={styles.select}>選擇遊戲</div>
        <div className={styles.select}>選擇角色</div>
      </div>
      <div className={styles.gd}>
        <div className={styles.title}>
          <div className={styles.hr}></div>
          <p>選擇管道</p>
        </div>
        <div className={styles.con}>
          <div className={styles.item} onClick={()=>{setShowRecharge(true)}}>
            <img src={g1} alt="" />
          </div>
          <div className={styles.item} onClick={()=>{setShowRecharge(true)}}>
            <img src={g2} alt="" />
          </div>
          <div className={styles.item} onClick={()=>{setShowRecharge(true)}}>
            <img src={g3} alt="" />
          </div>
        </div>
      </div>
      {showRecharge && <Reacharge onClose={()=>{setShowRecharge(false)}}></Reacharge>}
     {showLogin &&  <Login onClose={()=>{setShowLogin(false)}}></Login>}
    </div>
      <Footer></Footer>
    </>
  )
}

export default Index;
